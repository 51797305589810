import * as React from 'react'
import styled from 'styled-components'
import styledTs from 'styled-components-ts'
import CommentDisplay from './CommentDisplay'
import is from 'styled-is'
import {
  faComments,
  faLock,
  faLockOpen,
  faTrash
} from '@fortawesome/fontawesome-free-solid'
import * as get from 'lodash/get'
import * as truncate from 'lodash/truncate'
import ReportTarget from './ReportTarget'
import { AnyFunction } from '../types/AnyFunction'
import { withApollo, compose } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import createAction from '../helpers/createAction'
import Optimistic from './Optimistic'
import FontAwesome from '@fortawesome/react-fontawesome'
import { deleteThread, lockThread, unLockThread } from '../queries/thread'

const { REACT_APP_MAIN_SITE_URL } = process.env

const Title = styledTs<{ bold: boolean }>(styled.h3)`
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  line-height: 1.5;
  color: var(--black);

  ${is('bold')`
    font-weight: bold;
  `}
`

type Props = {
  refetchReports: AnyFunction
  markAsVisited: AnyFunction
  isUnseen: boolean
  locked?: boolean
  report: any
  set?: AnyFunction
  client?: ApolloClient<any>
}

const enhance = compose(
  Optimistic(({ report }) => get(report, 'target.id'), 'report.target.locked'),
  withApollo
)

@enhance
class Thread extends React.Component<Props, any> {
  lock = () => {
    const {
      set,
      client,
      report: { target }
    } = this.props
    set(true)

    client.mutate({
      mutation: lockThread,
      variables: {
        threadId: target.id
      }
    })
  }

  unlock = () => {
    const {
      set,
      client,
      report: { target }
    } = this.props
    set(false)

    client.mutate({
      mutation: unLockThread,
      variables: {
        threadId: target.id
      }
    })
  }

  delete = (confirmModal) => {
    const {
      report: { target },
      client
    } = this.props

    target !== null
      ? confirmModal('poistaa keskustelun', target.title, () => {
          client
            .mutate({
              mutation: deleteThread,
              variables: {
                id: target.id
              }
            })
            .then(() => this.props.refetchReports())
        })
      : () => {}
  }

  createModerationActions = (confirmModal) => [
    ...(this.props.report.target && this.props.report.target.id
      ? [
          createAction(
            'Näytä keskustelu',
            faComments,
            `${REACT_APP_MAIN_SITE_URL}/${this.props.report.target.url}`
          ),
          createAction('Poista keskustelu', faTrash, () =>
            this.delete(confirmModal)
          ),
          this.isLocked()
            ? createAction('Avaa keskustelu', faLockOpen, () => this.unlock())
            : createAction('Lukitse keskustelu', faLock, () => this.lock())
        ]
      : [])
  ]

  isLocked = () => {
    const {
      report: {
        target: { locked }
      }
    } = this.props

    return locked
  }

  render() {
    const { report, isUnseen, ...props } = this.props
    const { title = '', numReplies = 0 } = report.target

    return (
      <ReportTarget
        {...props}
        report={report}
        createModerationActions={this.createModerationActions}>
        {report.target && report.target.id ? (
          <Title bold={isUnseen}>
            {truncate(title, { length: 105 })} <CommentDisplay count={numReplies} />{' '}
            {this.isLocked() && (
              <FontAwesome style={{ marginLeft: '1rem' }} icon={faLock} />
            )}
          </Title>
        ) : (
          <i>(ketju on poistettu)</i>
        )}
      </ReportTarget>
    )
  }
}

export default Thread
