import * as React from 'react'
import { AnyFunction } from '../types/AnyFunction'
import styled from 'styled-components'
import is from 'styled-is'

export interface InputProps {
  type?: string
  onChange?: AnyFunction
  value?: string
  checked?: boolean
  autoComplete?: string
  name: string
  id?: string
  className?: string
  style?: any
  placeholder?: string
  disabled?: boolean
  min?: any
  max?: any
}

const Input = styled.input`
  border: solid 2px #ccc;
  background: rgba(255, 255, 255, 0.7);
  padding: 0.5rem 0.65rem;

  ${is('disabled')`
    background: none;
    color: var(--black);
  `};
`

export default ({ className, ...rest }: InputProps) => (
  <Input className={className} {...rest} />
)
