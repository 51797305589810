import * as React from 'react'
import styled from 'styled-components'
import Button from './Button'
import FontAwesome from '@fortawesome/react-fontawesome'
import { faBalanceScale } from '@fortawesome/fontawesome-free-solid'
import gql from 'graphql-tag'
import * as get from 'lodash/get'
import { mutation } from 'react-apollo/mutation-hoc'
import { AnyFunction } from '../types/AnyFunction'
import { compose, withApollo } from 'react-apollo'
import { ConfirmModalContext } from '../helpers/ConfirmModal'
import { ApolloClient } from 'apollo-client'

const ReportItem = styled.div`
  padding: 0.5rem 0 0.833rem;
  position: relative;
  color: #444444;
  line-height: 1.25;
  font-size: 0.889rem;
`

const Actions = styled.div`
  padding: 0 1.25rem;
  max-width: 20rem;
  margin: 2rem auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  > button,
  > a {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

type Props = {
  id?: string
  message?: string
  numDuplicates: number
  reporter?: any
  reason?: string
  getActions: AnyFunction
  renderReportContent?: AnyFunction
  mutate?: AnyFunction
  mutationLoading?: boolean
  children?: React.ReactNode
  client?: ApolloClient<any>
  refetchReports?: AnyFunction
}

const removeReport = gql`
  mutation deleteReport($id: ID!) {
    deleteReport(id: $id)
  }
`

const enhance = compose(mutation(removeReport), withApollo)

export default enhance(
  ({
    mutate: deleteReport,
    mutationLoading,
    getActions,
    renderReportContent,
    client,
    refetchReports,
    children,
    ...report
  }: Props) => {
    const { reason, message, reporter, numDuplicates } = report

    const reportContent = (
      <>
        <p>
          <strong>Syy:</strong> {reason}
        </p>
        <p>
          <strong>Lisätiedot:</strong> {message}
        </p>
        <p>
          <strong>Ilmiantaja:</strong>{' '}
          {get(reporter, 'demiProfile.username', 'Poistunut demittäjä')}
          {numDuplicates > 0 ? ` ja ${numDuplicates} muuta` : ''}
        </p>
      </>
    )

    return (
      <ReportItem>
        {reportContent}
        {renderReportContent && renderReportContent(report)}
        <ConfirmModalContext.Consumer>
          {(confirmWithModal) => (
            <Actions>
              <Button
                onClick={() =>
                  confirmWithModal('poistaa raportin', reportContent, async () => {
                    await deleteReport({ variables: { id: report.id } })

                    await refetchReports()
                  })
                }>
                <FontAwesome icon={faBalanceScale} />
                Poista ilmianto
              </Button>
              {getActions(confirmWithModal).map((actionConf, idx) => {
                if (typeof actionConf === 'function') {
                  return actionConf(report)
                }

                const { label, action, icon } = actionConf

                return (
                  <Button key={`action_${idx}`} {...action}>
                    <FontAwesome icon={icon} />
                    {label}
                  </Button>
                )
              })}
              {children}
            </Actions>
          )}
        </ConfirmModalContext.Consumer>
      </ReportItem>
    )
  }
)
