import * as React from 'react'
import styled from 'styled-components'
import ListHeader from '../components/ListHeader'
import Reports from '../views/Reports'
import { compose } from 'react-apollo'
import { nodeIdFromReportsQuery } from '../helpers/nodeIdFromReportsQuery'
import visitedItemsManager from '../components/visitedItemsManager'
import { observer } from 'mobx-react'

const ListSection = styled.div`
  margin-bottom: 2rem;
`

type State = {}

const enhance = compose(
  visitedItemsManager('SEEN_REPORTS', ({ queryResult }) =>
    nodeIdFromReportsQuery(queryResult)
  ),
  observer
)

@enhance
class Dashboard extends React.Component<any, State> {
  state: State = {}

  render() {
    const { markAsVisited, visitedItems } = this.props
    const site = process.env.REACT_APP_SITE

    return (
      <div>
        {site === 'lily' && (
          <ListSection>
            <ListHeader heading="Ei ilmiantoja" />
          </ListSection>
        )}
        {site === 'demi' && (
          <ListSection>
            <ListHeader heading="Ilmiannetut keskustelut" />
            <Reports
              visitedItems={visitedItems}
              markAsVisited={markAsVisited}
              // Workaround for https://github.com/apollographql/apollo-client/issues/3948
              variables={{ targetType: 'Thread', cursor: null, perPage: 5 }}
            />
          </ListSection>
        )}
        {site === 'demi' && (
          <ListSection>
            <ListHeader heading="Ilmiannetut kommentit" />
            <Reports
              visitedItems={visitedItems}
              markAsVisited={markAsVisited}
              // Workaround for https://github.com/apollographql/apollo-client/issues/3948
              variables={{ targetType: 'Reply', cursor: null, perPage: 5 }}
            />
          </ListSection>
        )}
        {site === 'demi' && (
          <ListSection>
            <ListHeader heading="Ilmiannetut käyttäjät" />
            <Reports
              visitedItems={visitedItems}
              markAsVisited={markAsVisited}
              // Workaround for https://github.com/apollographql/apollo-client/issues/3948
              variables={{ targetType: 'DemiProfile', cursor: null, perPage: 5 }}
            />
          </ListSection>
        )}
      </div>
    )
  }
}

export default Dashboard
